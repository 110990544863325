import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import React, { ChangeEvent, FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useStatisticTitle, {
  StatisticType,
} from '../../../../hooks/statistic/useStatisticTitle'
import { RootState } from '../../../../redux/store'
import { getInternationalizedValue } from '../../../../utils/common'
import StatProcessing from '../statProcessing'
import StatResume from '../statResume'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: '#FCFCFC',
    padding: 24,
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  },
  title: {
    fontWeight: 600,
    fontSize: 40,
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    '@media (max-width: 410px)': {
      fontSize: 16,
    },
  },
  selection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 0px 37px 0',
    '@media (max-width: 410px)': {
      margin: '0px 0px 18px 0',
    },
  },
  borderLeft: {
    width: 16,
    height: 32,
    backgroundColor: '#CABDFF',
    marginRight: 16,
    borderRadius: 4,
  },
  select: {
    padding: '6px',
    borderRadius: '10px !important',
    fontSize: 14,
    color: '#6F767E',
  },
  input: {
    borderRadius: '10px !important',
  },
  menuItem: {
    color: '#6F767E',
    fontSize: 14,
  },
  typography: {
    '@media (max-width: 415px)': {
      fontSize: 12,
    },
  },
}))

const StatHeader: FC = () => {
  const classes = useStyles()

  const { language } = useSelector((state: RootState) => state.app)

  const data = useStatisticTitle()

  const title = getInternationalizedValue(data, 'title', language)

  const [selected, setSelected] = useState<StatisticType>('CREATION')

  const selectedTitle = useMemo(
    () =>
      getInternationalizedValue(
        data.types.find(item => item.value === selected)!,
        'name',
      ),
    [selected],
  )

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.value as StatisticType)
  }

  return (
    <div>
      <Typography className={classes.title} variant="h4">
        {title}
      </Typography>
      <Box className={classes.container}>
        <Box className={classes.selection} my={3}>
          <Box display="flex">
            <div className={classes.borderLeft} />
            <Typography className={classes.typography}>
              {selectedTitle}
            </Typography>
          </Box>
          <TextField
            select={true}
            value={selected}
            variant="outlined"
            onChange={handleChange}
            classes={{}}
            SelectProps={{ classes: { outlined: classes.select } }}
            InputProps={{
              classes: {
                notchedOutline: classes.input,
              },
            }}
          >
            {data.types.map(
              item =>
                item.active && (
                  <MenuItem
                    className={classes.menuItem}
                    key={item.id}
                    value={item.value}
                  >
                    {getInternationalizedValue(item, 'name')}
                  </MenuItem>
                ),
            )}
          </TextField>
        </Box>
        <StatProcessing />
        <StatResume />
      </Box>
    </div>
  )
}

export default StatHeader
