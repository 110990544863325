import { graphql, useStaticQuery } from 'gatsby'

export interface StatisticHeaderInterface {
  id: string
  title_fr: string
  title_mg: string
  title_en: string
  types: StatisticTypeInterface[]
}

export interface StatisticTypeInterface {
  id: string
  name_en: string
  name_fr: string
  name_mg: string
  value: StatisticType
  active: boolean
}

export type StatisticType = 'CREATION' | 'RADIATION' | 'MODIFICATION'

export default (): StatisticHeaderInterface => {
  const data = useStaticQuery(graphql`
    {
      strapiStatistic {
        id
        title_fr
        title_mg
        title_en
        types {
          id
          name_en
          name_fr
          name_mg
          value
          active
        }
      }
    }
  `)

  return data.strapiStatistic
}
