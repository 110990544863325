import Box from '@material-ui/core/Box'
import React, { FC } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useStatisticProcessing from '../../../../hooks/statistic/useStatisticProcessing'
import {
  getInternationalizedValue,
  readableMinute,
} from '../../../../utils/common'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import TableRowProcessing from './TableRowProcessing'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: 20,
    backgroundColor: 'rgba(222, 247, 236, 0.48)',
    padding: '11px 12px',
  },
  title: {
    fontSize: 40,
    textDecoration: 'underline',
    marginBottom: 12,
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    '@media (max-width: 410px)': {
      fontSize: 16,
    },
  },
  containerTable: {
    borderRadius: 20,
    backgroundColor: '#FFFFFF',
    '& td': {
      padding: '10px 20px',
      [theme.breakpoints.down('md')]: {
        padding: '6px 12px',
      },
    },
    '& th': {
      padding: '10px 20px',
      [theme.breakpoints.down('md')]: {
        padding: '6px 12px',
        fontSize: 20,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
      '@media (max-width: 400px)': {
        fontSize: 10,
        padding: '2px 4px',
      },
    },
  },
  tdHead: {
    fontSize: 20,
    color: '#00AB55',
    fontWeight: 400,
  },
  table: {
    width: '100%',
  },
  tbody: {
    width: '100%',
  },
  totalLabel: {
    color: '#858585',
    fontSize: 30,
    textAlign: 'right',
    paddingTop: '40px  !important',
    paddingBottom: '30px  !important',
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      paddingTop: '30px  !important',
      paddingBottom: '20px  !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      paddingTop: '15px  !important',
      paddingBottom: '10px  !important',
    },
    '@media (max-width: 400px)': {
      fontSize: 10,
    },
  },
  totalValue: {
    fontSize: 30,
    fontWeight: 600,
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    '@media (max-width: 400px)': {
      fontSize: 10,
    },
  },
}))

const StatProcessing: FC = () => {
  const classes = useStyles()

  const {
    language,
    interval: {
      intervals: {
        admissible,
        immatriculation,
        inProgress,
        pendingPayment,
        ready,
        submission,
      },
      totalDelay,
    },
  } = useSelector((state: RootState) => ({
    language: state.app.language,
    interval: state.statistic.interval,
  }))

  const data = useStatisticProcessing()

  const title = getInternationalizedValue(data, 'title', language)

  return (
    <Box className={classes.container}>
      <Typography className={classes.title} component="h3">
        {title}
      </Typography>
      <Box className={classes.containerTable}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th />
              <th className={classes.tdHead}>
                {getInternationalizedValue(data, 'titleColumnDelay', language)}
              </th>
              <th className={classes.tdHead}>
                {getInternationalizedValue(data, 'titleColumnTotal', language)}
              </th>
            </tr>
          </thead>
          <tbody className={classes.tbody}>
            <TableRowProcessing
              type={data.submitedFolder}
              position={1}
              data={submission}
            />
            <TableRowProcessing
              type={data.inProgress}
              position={2}
              data={inProgress}
            />
            <TableRowProcessing
              type={data.admissibleFolder}
              position={3}
              data={admissible}
            />
            <TableRowProcessing
              type={data.waitingPayment}
              position={4}
              data={pendingPayment}
            />
            <TableRowProcessing
              type={data.registrationInProgress}
              position={5}
              data={immatriculation}
            />
            <TableRowProcessing
              type={data.documentReady}
              position={6}
              data={ready}
            />
            <tr>
              <td className={classes.totalLabel}>
                {getInternationalizedValue(
                  data,
                  'ProcessingTotalAverage',
                  language,
                )}
              </td>
              <td className={classes.totalValue}>
                {readableMinute(totalDelay)}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Box>
  )
}

export default StatProcessing
