import React, { FC } from 'react'
import { StatisticTypeProcessingInterface } from '../../../../../hooks/statistic/useStatisticProcessing'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux/store'
import {
  formatNumber,
  getInternationalizedValue,
  readableMinute,
} from '../../../../../utils/common'
import { StatisticIntervalItem } from '../../../../../interfaces/statistic'

const useStyles = makeStyles((theme: Theme) => ({
  td1: {
    display: 'flex',
    alignItems: 'center',
  },
  number: {
    backgroundColor: '#00AB55EB',
    borderRadius: 32,
    width: 24,
    height: 24,
    color: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 49,
    [theme.breakpoints.down('md')]: {
      marginRight: 20,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 10,
      borderRadius: 32,
      width: 16,
      height: 16,
      fontSize: 12,
    },
  },
  type: {
    backgroundColor: '#27D27C4F',
    color: '#00AB55',
    borderRadius: 15,
    width: '100%',
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 30,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    '@media (max-width: 400px)': {
      fontSize: 10,
    },
  },
  td2td3: {
    fontSize: 30,
    fontWeight: 600,
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    '@media (max-width: 400px)': {
      fontSize: 10,
    },
  },
  tr: {
    width: '100%',
  },
}))

interface TableRowProcessingProps {
  type: StatisticTypeProcessingInterface
  position: number
  data: StatisticIntervalItem
}

const TableRowProcessing: FC<TableRowProcessingProps> = ({
  type,
  position,
  data,
}) => {
  const classes = useStyles()

  const { language } = useSelector((state: RootState) => state.app)

  return (
    <tr className={classes.tr}>
      <td className={classes.td1}>
        <span className={classes.number}>{position}</span>
        <span className={classes.type}>
          {getInternationalizedValue(type, 'name', language)}
        </span>
      </td>
      <td className={classes.td2td3}>
        {position === 1 ? '' : readableMinute(data.delay)}
      </td>
      <td className={classes.td2td3}>{formatNumber(data.total, ',')}</td>
    </tr>
  )
}

export default TableRowProcessing
