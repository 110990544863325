import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import StatChart from '../components/pages/statistic/statChart'
import StatHeader from '../components/pages/statistic/statHeader'
import SEO from '../components/seo'
import {
  getStaticGraphSaga,
  getStatisticIntervalSaga,
  getStatisticOverviewSaga,
} from '../redux/modules/statistic'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      margin: `0px ${theme.spacing(1)}px 0px ${theme.spacing(1)}px`,
    },
    [theme.breakpoints.up('md')]: {
      margin: `0px ${theme.spacing(7)}px 0px ${theme.spacing(7)}px`,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up('lg')]: {
      margin: `0px ${theme.spacing(15)}px 0px ${theme.spacing(15)}px`,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}))

const Statistic: FC = () => {
  const classes = useStyles()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getStatisticOverviewSaga())
    dispatch(getStatisticIntervalSaga())
    dispatch(getStaticGraphSaga())
  }, [])

  return (
    <>
      <SEO title="Statistique" />
      <div className={classes.container}>
        <StatHeader />
        <StatChart />
      </div>
    </>
  )
}

export default Statistic
