import Typography from '@material-ui/core/Typography'
import React, { FC } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 227,
    width: '100%',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 16,
    padding: '14px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      height: 180,
    },
    [theme.breakpoints.down('sm')]: {
      height: 100,
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  value: (props: { color: string }) => ({
    color: props.color,
    fontSize: 80,
    lineHeight: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: 40,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  }),
}))

interface ItemResumeProps {
  title: string
  value: string
  color: string
}

const ItemResume: FC<ItemResumeProps> = ({ title, value, color }) => {
  const classes = useStyles({ color })
  return (
    <div className={classes.container}>
      <Typography component="label" className={classes.title}>
        {title}
      </Typography>
      <Typography component="p" className={classes.value}>
        {value}
      </Typography>
    </div>
  )
}

export default ItemResume
