import { graphql, useStaticQuery } from 'gatsby'

export interface StatisticChartInterface {
  id: string
  labelProcessedFolder_en: string
  labelProcessedFolder_fr: string
  labelProcessedFolder_mg: string
  labelReceivedFolder_en: string
  labelReceivedFolder_fr: string
  labelReceivedFolder_mg: string
  title_en: string
  title_fr: string
  title_mg: string
}

export default (): StatisticChartInterface => {
  const data = useStaticQuery(graphql`
    {
      strapiStatistic {
        id
        graph {
          id
          labelProcessedFolder_en
          labelProcessedFolder_fr
          labelProcessedFolder_mg
          labelReceivedFolder_en
          labelReceivedFolder_fr
          labelReceivedFolder_mg
          title_en
          title_fr
          title_mg
        }
      }
    }
  `)

  return data.strapiStatistic.graph
}
