import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
} from 'recharts'
import useStatisticChart from '../../../../hooks/statistic/useStatisticChart'
import { RootState } from '../../../../redux/store'
import { getInternationalizedValue } from '../../../../utils/common'
import BoxLabel from './BoxLabel'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(2),
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 18,
      paddingRight: 18,
    },
  },
  title: {
    color: '#2C2C2C',
    fontSize: 28,
    fontWeight: 500,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      marginBottom: theme.spacing(2),
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    width: '100%',
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.05)',
    borderRadius: 10,
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    '@media (max-width: 400px)': {
      padding: theme.spacing(1),
    },
  },
}))

const StatChart: FC = () => {
  const classes = useStyles()

  const data = useStatisticChart()

  const { language, graph } = useSelector((state: RootState) => ({
    language: state.app.language,
    graph: state.statistic.graph,
  }))

  const title = getInternationalizedValue(data, 'title', language)

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      <Box display="flex" justifyContent="space-around">
        <BoxLabel
          backgroundColor="#179D75"
          label={getInternationalizedValue(
            data,
            'labelReceivedFolder',
            language,
          )}
        />
        <BoxLabel
          backgroundColor="#81BCFF"
          label={getInternationalizedValue(
            data,
            'labelProcessedFolder',
            language,
          )}
        />
      </Box>
      <Box className={classes.paper}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={730}
            height={300}
            data={graph}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line
              name="Reçus"
              type="monotone"
              dataKey="received"
              stroke="#179D75"
            />
            <Line
              name="Traités"
              type="monotone"
              dataKey="processed"
              stroke="#81BCFF"
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </div>
  )
}

export default StatChart
