import { graphql, useStaticQuery } from 'gatsby'

export interface StatisticResumeInterface {
  id: string
  title_mg: string
  title_fr: string
  title_en: string
  validatingAdmissibilityDelay_mg: string
  validatingAdmissibilityDelay_fr: string
  validatingAdmissibilityDelay_en: string
  totalSubmitedFolder_mg: string
  totalSubmitedFolder_fr: string
  totalSubmitedFolder_en: string
  totalCreated_mg: string
  totalCreated_fr: string
  totalCreated_en: string
  minRegistrationInProgress_mg: string
  minRegistrationInProgress_fr: string
  minRegistrationInProgress_en: string
  maxValidationDelay_mg: string
  maxValidationDelay_fr: string
  maxValidationDelay_en: string
  maxRegistrationInProgress_mg: string
  maxRegistrationInProgress_fr: string
  maxRegistrationInProgress_en: string
  folderInProgress_mg: string
  folderInProgress_fr: string
  folderInProgress_en: string
  averageValidationDelay_mg: string
  averageValidationDelay_fr: string
  averageValidationDelay_en: string
  averageRegistration_mg: string
  averageRegistration_fr: string
  averageRegistration_en: string
}

export default (): StatisticResumeInterface => {
  const data = useStaticQuery(graphql`
    {
      strapiStatistic {
        id
        summaryTable {
          validatingAdmissibilityDelay_mg
          validatingAdmissibilityDelay_fr
          validatingAdmissibilityDelay_en
          totalSubmitedFolder_mg
          totalSubmitedFolder_fr
          totalSubmitedFolder_en
          totalCreated_mg
          totalCreated_fr
          totalCreated_en
          title_mg
          title_fr
          title_en
          minRegistrationInProgress_mg
          minRegistrationInProgress_fr
          minRegistrationInProgress_en
          maxValidationDelay_mg
          maxValidationDelay_fr
          maxValidationDelay_en
          maxRegistrationInProgress_mg
          maxRegistrationInProgress_fr
          maxRegistrationInProgress_en
          id
          folderInProgress_mg
          folderInProgress_fr
          folderInProgress_en
          averageValidationDelay_mg
          averageValidationDelay_fr
          averageValidationDelay_en
          averageRegistration_mg
          averageRegistration_fr
          averageRegistration_en
        }
      }
    }
  `)

  return data.strapiStatistic.summaryTable
}
