import { graphql, useStaticQuery } from 'gatsby'

export interface StatisticProcessingInterface {
  id: string
  titleColumnDelay_en: string
  titleColumnDelay_fr: string
  titleColumnDelay_mg: string
  titleColumnTotal_en: string
  titleColumnTotal_fr: string
  titleColumnTotal_mg: string
  title_en: string
  title_fr: string
  title_mg: string
  admissibleFolder: StatisticTypeProcessingInterface
  documentReady: StatisticTypeProcessingInterface
  registrationInProgress: StatisticTypeProcessingInterface
  submitedFolder: StatisticTypeProcessingInterface
  waitingPayment: StatisticTypeProcessingInterface
  inProgress: StatisticTypeProcessingInterface
  ProcessingTotalAverage_en: string
  ProcessingTotalAverage_fr: string
  ProcessingTotalAverage_mg: string
}

export interface StatisticTypeProcessingInterface {
  id: string
  name_en: string
  name_fr: string
  name_mg: string
  value: string
}

export default (): StatisticProcessingInterface => {
  const data = useStaticQuery(graphql`
    {
      strapiStatistic {
        id
        processing {
          id
          titleColumnDelay_en
          titleColumnDelay_fr
          titleColumnDelay_mg
          titleColumnTotal_en
          titleColumnTotal_fr
          titleColumnTotal_mg
          title_en
          title_fr
          title_mg
          admissibleFolder {
            id
            name_en
            name_fr
            name_mg
            value
          }
          documentReady {
            id
            name_en
            name_fr
            name_mg
            value
          }
          registrationInProgress {
            id
            name_en
            name_fr
            name_mg
            value
          }
          submitedFolder {
            id
            name_en
            name_fr
            name_mg
            value
          }
          waitingPayment {
            id
            name_en
            name_fr
            name_mg
            value
          }
          inProgress {
            id
            name_en
            name_fr
            name_mg
            value
          }
          ProcessingTotalAverage_en
          ProcessingTotalAverage_fr
          ProcessingTotalAverage_mg
        }
      }
    }
  `)

  return data.strapiStatistic.processing
}
