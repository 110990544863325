import Box from '@material-ui/core/Box'
import React, { FC } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
const useStyles = makeStyles((theme: Theme) => ({
  boxLabel: (props: { backgroundColor: string }) => ({
    width: 56,
    height: 56,
    borderRadius: 7,
    backgroundColor: props.backgroundColor,
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: 36,
      height: 36,
      marginRight: theme.spacing(2),
      borderRadius: 4,
    },
    '@media (max-width: 581px)': {
      width: 26,
      height: 26,
      marginRight: theme.spacing(1),
      borderRadius: 2,
    },
  }),
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    color: '#2C2C2C',
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    '@media (max-width: 581px)': {
      fontSize: 12,
    },
  },
}))

const BoxLabel: FC<{ backgroundColor: string; label: string }> = ({
  backgroundColor,
  label,
}) => {
  const classes = useStyles({ backgroundColor })
  return (
    <Box className={classes.container}>
      <Box className={classes.boxLabel} />
      <Typography component="label" className={classes.label}>
        {label}
      </Typography>
    </Box>
  )
}

export default BoxLabel
