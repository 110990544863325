import Grid from '@material-ui/core/Grid'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import useStatisticResume from '../../../../hooks/statistic/useStatisticResume'
import { RootState } from '../../../../redux/store'
import {
  formatNumber,
  getInternationalizedValue,
  readableMinute,
} from '../../../../utils/common'
import ItemResume from './ItemResume/ItemResume'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: 32,
  },
  title: {
    color: '#2C2C2C',
    fontSize: 28,
    fontWeight: 500,
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  gridContainer: {
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '0px 32px',
  },
}))

const StatResume: FC = () => {
  const classes = useStyles()

  const {
    language,
    overview: {
      approval,
      createdCompany,
      inProgress,
      immatriculation,
      submissionFolder,
    },
  } = useSelector((state: RootState) => ({
    language: state.app.language,
    overview: state.statistic.overview,
  }))

  const data = useStatisticResume()
  const title = getInternationalizedValue(data, 'title', language)

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      <Grid container={true} spacing={2} className={classes.gridContainer}>
        <Grid item={true} lg={4} md={6} sm={6} xs={12}>
          <ItemResume
            title={getInternationalizedValue(
              data,
              'totalSubmitedFolder',
              language,
            )}
            value={formatNumber(submissionFolder, ',')}
            color="#7A7D9F"
          />
        </Grid>

        <Grid item={true} lg={4} md={6} sm={6} xs={12}>
          <ItemResume
            title={getInternationalizedValue(data, 'totalCreated', language)}
            value={formatNumber(createdCompany, ',')}
            color="#3687D8"
          />
        </Grid>
        <Grid item={true} lg={4} md={6} sm={6} xs={12}>
          <ItemResume
            title={getInternationalizedValue(
              data,
              'folderInProgress',
              language,
            )}
            value={formatNumber(inProgress, ',')}
            color="#FF4A4A"
          />
        </Grid>
        <Grid item={true} lg={4} md={6} sm={6} xs={12}>
          <ItemResume
            title={getInternationalizedValue(
              data,
              'validatingAdmissibilityDelay',
              language,
            )}
            value={readableMinute(approval.minDelay)}
            color="#FF7467"
          />
        </Grid>
        <Grid item={true} lg={4} md={6} sm={6} xs={12}>
          <ItemResume
            title={getInternationalizedValue(
              data,
              'maxValidationDelay',
              language,
            )}
            value={readableMinute(approval.maxDelay)}
            color="#FA1E1E"
          />
        </Grid>
        <Grid item={true} lg={4} md={6} sm={6} xs={12}>
          <ItemResume
            title={getInternationalizedValue(
              data,
              'averageValidationDelay',
              language,
            )}
            value={readableMinute(approval.avgDelay)}
            color="#179D75"
          />
        </Grid>
        <Grid item={true} lg={4} md={6} sm={6} xs={12}>
          <ItemResume
            title={getInternationalizedValue(
              data,
              'minRegistrationInProgress',
              language,
            )}
            value={readableMinute(immatriculation.minDelay)}
            color="#FF7467"
          />
        </Grid>
        <Grid item={true} lg={4} md={6} sm={6} xs={12}>
          <ItemResume
            title={getInternationalizedValue(
              data,
              'maxRegistrationInProgress',
              language,
            )}
            value={readableMinute(immatriculation.maxDelay)}
            color="#FA1E1E"
          />
        </Grid>
        <Grid item={true} lg={4} md={6} sm={6} xs={12}>
          <ItemResume
            title={getInternationalizedValue(
              data,
              'averageRegistration',
              language,
            )}
            value={readableMinute(immatriculation.avgDelay)}
            color="#179D75"
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default StatResume
